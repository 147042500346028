'use client';

import { SessionProvider, getSession, signOut } from 'next-auth/react';
import { PropsWithChildren, useEffect, useState } from 'react';
import { usePathname } from 'next/navigation';

import { SocialAuthContext } from './useSocialAuth';

import { LoginMessageEnum, SocialLoginProvider } from '@/shared/types';

const popupCenter = (url: string, title: string) => {
  if (typeof window === 'undefined') return null;

  const { searchParams } = new URL(url, window.location.origin);

  const dualScreenLeft = window.screenLeft ?? window.screenX;
  const dualScreenTop = window.screenTop ?? window.screenY;

  const width =
    window.innerWidth ?? document.documentElement.clientWidth ?? screen.width;

  const height =
    window.innerHeight ??
    document.documentElement.clientHeight ??
    screen.height;

  const popupWidth = searchParams.get('provider') === 'google' ? 550 : 1050;
  const popupHeight = searchParams.get('provider') === 'google' ? 700 : 630;

  const left = width / 2 - popupWidth / 2 + dualScreenLeft;
  const top = height / 2 - popupHeight / 2 + dualScreenTop;

  const newWindow = window.open(
    url,
    title,
    `width=${popupWidth},height=${popupHeight},top=${top},left=${left}`
  );

  newWindow?.focus();

  return newWindow;
};

const SocialAuthProvider = ({ children }: PropsWithChildren) => {
  const [socialLoginMessage, setSocialLoginMessage] =
    useState<LoginMessageEnum | null>(null);
  const [socialProvider, setSocialProvider] =
    useState<SocialLoginProvider | null>(null);
  const [socialAccessToken, setSocialAccessToken] = useState<string | null>('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSocialLoginPopupOpen, setIsSocialLoginPopupOpen] = useState(false);

  const pathname = usePathname();

  const resetSocialAuthData = () => {
    setSocialLoginMessage(null);
    setSocialProvider(null);
    setSocialAccessToken('');
    setIsLoading(false);
    setIsSocialLoginPopupOpen(false);
    signOut({ redirect: false });
  };

  const fetchSocialLogin = async (provider: SocialLoginProvider) => {
    setSocialProvider(provider);
    setIsLoading(true);

    try {
      let popup = popupCenter(`/popup?provider=${provider}`, '소셜 로그인');
      setIsSocialLoginPopupOpen(true);

      const popupInterval = window.setInterval(function () {
        try {
          // 창이 꺼졌는지 판단
          if (popup == null || popup.closed) {
            window.clearInterval(popupInterval);
            popup = null;

            setIsSocialLoginPopupOpen(false);
          }
        } catch (e) {
          /* empty */
        }
      }, 350);
    } catch (e) {
      throw new Error();
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const signOutSocialSession = async () => {
      if (!socialAccessToken) return;

      const session = await getSession();

      if (!session) return;

      signOut({ redirect: false });
    };

    signOutSocialSession();

    return () => resetSocialAuthData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const contextValue = {
    isLoading,
    socialLoginMessageStatus: {
      get: socialLoginMessage,
      set: setSocialLoginMessage,
    },
    providerStatus: {
      get: socialProvider,
      set: setSocialProvider,
    },
    socialLoginPopupOpenStatus: {
      get: isSocialLoginPopupOpen,
      set: setIsSocialLoginPopupOpen,
    },
    socialAccessTokenStatus: {
      get: socialAccessToken,
      set: setSocialAccessToken,
    },
    fetchSocialLogin,
    resetSocialAuthData,
  };

  return (
    <SessionProvider>
      <SocialAuthContext.Provider value={contextValue}>
        {children}
      </SocialAuthContext.Provider>
    </SessionProvider>
  );
};

export default SocialAuthProvider;
