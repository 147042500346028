import { getCookie } from 'cookies-next';

import { clientAxios } from '@/shared/api';

const useAutoFillInterface = () => {
  const decodeBase64 = (base64String: string) => {
    return Buffer.from(base64String, 'base64').toString('utf-8');
  };

  const getAutoFillEmail = () => {
    const PrevEmail = getCookie('prev_email');

    if (!PrevEmail) return null;

    return decodeBase64(PrevEmail);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setAutoFillEmail = async (handleChange: ({ target }: any) => void) => {
    const autoFillEmail = getAutoFillEmail();

    if (!autoFillEmail) return;

    handleChange({
      target: { name: 'email', value: autoFillEmail },
    });

    await clientAxios.delete('/cookie/prev_email');
  };

  return { getAutoFillEmail, setAutoFillEmail };
};

export default useAutoFillInterface;
