'use client';

import { useState } from 'react';

import { clientAxios } from '@/shared/api';
import { getKoreanTimeString } from '@/shared/utils';

interface WithdrawalInfo {
  isWithdrawalRequested: boolean | undefined;
  dueDate: string;
}

const useGetWithdrawalInfo = () => {
  const [withdrawalInfo, setWithdrawalInfo] = useState<WithdrawalInfo>({
    isWithdrawalRequested: undefined,
    dueDate: '',
  });
  const [isLoading, setIsLoading] = useState(false);

  const fetchWithdrawalInfo = async () => {
    setIsLoading(true);
    try {
      const { data } = await clientAxios.get('/withdrawalInfoApi');

      if (data.ok) {
        if (data.data?.due_date !== '') {
          const { date } = getKoreanTimeString(data.data?.due_date);
          return setWithdrawalInfo({
            isWithdrawalRequested: true,
            dueDate: date,
          });
        }

        setWithdrawalInfo({
          isWithdrawalRequested: false,
          dueDate: '',
        });
      }
    } catch (e) {
      /* empty */
    } finally {
      setIsLoading(false);
    }
  };

  return {
    data: withdrawalInfo,
    setWithdrawalInfo,
    isLoading,
    fetch: fetchWithdrawalInfo,
  };
};

export default useGetWithdrawalInfo;
