'use client';

import { getSession, signOut } from 'next-auth/react';
import { useEffect, useState } from 'react';
import { isAxiosError } from 'axios';

import {
  LoginMessageEnum,
  SocialLoginData,
  SocialLoginProvider,
  SocialSession,
} from '@/shared/types';
import { ClientAuth } from '@/shared/auth';
import { clientAxios } from '@/shared/api';
import { useAuthCompletion, useDialog } from '@/shared/hooks';
import { useSocialAuth } from '@/shared/providers';
import {
  deleteAnonymousClientUuid,
  getAnonymousClientUuid,
} from '@/shared/utils/auth';
import { notifyToast } from '@/shared/utils';

interface SocialLoginResponse {
  ok: boolean;
  data: Omit<SocialLoginData, 'providerAccessToken'>;
}

const useSocialLogin = () => {
  const [data, setData] = useState<SocialLoginData | null>(null);
  const [isLogin, setIsLogin] = useState<boolean | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const { hasAgreement } = useDialog();
  const {
    providerStatus,
    socialLoginPopupOpenStatus,
    socialAccessTokenStatus,
    socialLoginMessageStatus,
    resetSocialAuthData,
  } = useSocialAuth();
  const { redirectNext, referrerService } = useAuthCompletion();

  const accessSocialLogin = async (isAppliedDelete = false) => {
    const session = (await getSession()) as SocialSession;

    if (!session || !session.account) return setIsLogin(false);

    const accessToken = session.account.access_token;
    const provider = session.account.provider as SocialLoginProvider;

    if (!accessToken || !provider) return setIsLogin(false);

    socialAccessTokenStatus.set(accessToken);
    providerStatus.set(provider);

    const isAccessed = Boolean(await ClientAuth.getAccessToken());

    if (isAccessed) return setIsLogin(true);

    try {
      setIsLoading(true);

      const anonymousClientUuid = getAnonymousClientUuid();

      const { data } = await clientAxios.post<SocialLoginResponse>(
        '/socialLoginApi',
        {
          provider,
          accessToken,
          service: referrerService,
          anonymousClientUuid,
          isAppliedDelete,
        }
      );

      if (data.ok) {
        setData({ ...data.data, providerAccessToken: accessToken });

        const socialLoginResponseMessage = data.data.msg;
        socialLoginMessageStatus.set(socialLoginResponseMessage);

        if (socialLoginResponseMessage === LoginMessageEnum.CAN_LOGIN) {
          const accessToken = data.data.access as string;
          const refreshToken = data.data.refresh as string;

          await authenticateSocialLogin(accessToken, refreshToken);
          await deleteAnonymousClientUuid();
        } else if (
          socialLoginResponseMessage === LoginMessageEnum.NEED_SIGNUP
        ) {
          setIsLogin(true);
        } else if (
          socialLoginResponseMessage === LoginMessageEnum.NEED_LINK ||
          socialLoginResponseMessage === LoginMessageEnum.PARTNER_HAS_PROJECT
        ) {
          return;
        }
        redirectNext(socialLoginResponseMessage);
      } else {
        throw new Error();
      }
    } catch (e) {
      if (isAxiosError(e) && e.response) {
        const {
          error: { status },
        } = e.response.data;

        if (status === 401 && provider === 'facebook')
          notifyToast({
            type: 'error',
            message: '이메일 엑세스 권한을 허용해주세요.',
          });
      }

      await handleDeAuthentication();
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeAuthentication = async () => {
    try {
      await ClientAuth.deAuthenticate();
    } catch (error) {
      console.error('Deauthentication failed:', JSON.stringify(error));
    } finally {
      setIsLogin(false);
      resetSocialLoginStatus();
    }
  };

  const authenticateSocialLogin = async (access: string, refresh: string) => {
    await ClientAuth.authenticate(access, refresh);

    await signOut({ redirect: false });
    setIsLogin(true);
  };

  const resetSocialLoginStatus = () => {
    setData(null);
    setIsLogin(undefined);
    setIsLoading(false);
    resetSocialAuthData();
  };

  useEffect(() => {
    const handleSocialLogin = async () => {
      if (!socialLoginPopupOpenStatus.get) {
        await accessSocialLogin(hasAgreement);
      }
    };

    handleSocialLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socialLoginPopupOpenStatus.get, hasAgreement]);

  return {
    isLogin,
    data,
    isLoading,
    reset: resetSocialLoginStatus,
  };
};

export default useSocialLogin;
