'use client';

import { useRouter } from 'next/navigation';

import useNextUrl from '../useNextUrl';

import { getReferrerService } from '@/shared/utils';
import { PATH } from '@/shared/constants';
import { LoginMessageEnum } from '@/shared/types';

const useAuthCompletion = () => {
  const router = useRouter();
  const { nextUrl, withNextUrl, redirectNextUrl } = useNextUrl();
  const referrerService = getReferrerService(nextUrl);

  const redirectAfterSignup = ({ isSocialSignUp = false } = {}) => {
    switch (referrerService) {
      case 'yozmit':
        return router.replace(withNextUrl(PATH.SIGNUP_COMPLETE));
      case 'superstack':
        return router.replace(withNextUrl(PATH.SIGNUP_COMPLETE));
      case 'wishket':
        return router.replace(PATH.WISHKET);
      default:
        return router.replace(
          withNextUrl(isSocialSignUp ? PATH.HOME : PATH.SIGNUP_EMAIL_VERIFY)
        );
    }
  };

  const redirectNext = (loginResponseMessage?: LoginMessageEnum) => {
    if (loginResponseMessage === LoginMessageEnum.NEED_SIGNUP) {
      return router.replace(withNextUrl(PATH.SIGNUP_SOCIAL));
    }

    switch (referrerService) {
      case 'yozmit':
        return router.replace(redirectNextUrl(PATH.YOZMIT));
      case 'superstack':
        return router.replace(redirectNextUrl(PATH.JOBS));
      case 'wishket':
        return router.replace(redirectNextUrl(PATH.WISHKET));
      default:
        if (loginResponseMessage === LoginMessageEnum.NOT_ACTIVE_BY_EMAIL) {
          return router.replace(withNextUrl(PATH.SIGNUP_EMAIL_VERIFY));
        }
        if (loginResponseMessage === LoginMessageEnum.CAN_LOGIN) {
          if (nextUrl) return router.replace(nextUrl);
        }
        return router.replace(withNextUrl(PATH.HOME));
    }
  };

  return { referrerService, redirectNext, redirectAfterSignup };
};

export default useAuthCompletion;
