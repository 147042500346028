'use client';

import { PropsWithChildren, useEffect, useState } from 'react';
import { usePathname, useRouter } from 'next/navigation';
import { LoadingIndicator } from '@wishket/design-system';

import { ClientAuth } from '@/shared/auth';
import { PATH } from '@/shared/constants';
import { useNextUrl } from '@/shared/hooks';

const A_SECOND = 1000;
const FIVE_SECONDS = 5 * A_SECOND;

const AuthGuardProvider = ({ children }: PropsWithChildren) => {
  const [isLogin, setIsLogin] = useState<boolean>(false);
  const router = useRouter();
  const pathname = usePathname();
  const { nextUrl, withNextUrl } = useNextUrl();

  useEffect(() => {
    if (!nextUrl && pathname === PATH.SIGNUP_COMPLETE) return router.back();

    const validAuth = async () => {
      const accessToken = await ClientAuth.getAccessToken();
      setIsLogin(!!accessToken);

      return !!accessToken;
    };

    const revalidateAuth = () => {
      validAuth().then((res) => {
        if (!res) {
          router.replace(withNextUrl(PATH.LOGIN));
          ClientAuth.deAuthenticate();
        }
      });
    };

    revalidateAuth();

    const intervalTime = FIVE_SECONDS;
    const intervalId = setInterval(revalidateAuth, intervalTime);

    window.addEventListener('focus', revalidateAuth);

    return () => {
      clearInterval(intervalId);
      window.removeEventListener('focus', revalidateAuth);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLogin) {
    return <>{children}</>;
  }

  return <LoadingIndicator size="lg" isFixed />;
};

export default AuthGuardProvider;
