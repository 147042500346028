'use client';

import { PropsWithChildren, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import DeviceCheckContext from './DeviceCheckContext';

const DeviceCheckProvider = ({ children }: PropsWithChildren) => {
  const desktop = useMediaQuery({
    query: '(min-width: 960px)',
  });
  const tablet = useMediaQuery({
    query: '(min-width: 720px) and (max-width: 960px)',
  });
  const mobile = !desktop && !tablet;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsDesktop(desktop);
    setIsLoading(false);
  }, [desktop]);

  useEffect(() => {
    setIsTablet(tablet);
    setIsLoading(false);
  }, [tablet]);

  useEffect(() => {
    setIsMobile(mobile);
    setIsLoading(false);
  }, [mobile]);

  return (
    <DeviceCheckContext.Provider
      value={{ isMobile, isTablet, isDesktop, isLoading }}
    >
      {children}
    </DeviceCheckContext.Provider>
  );
};

export default DeviceCheckProvider;
