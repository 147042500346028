import { Box, Divider, Typography } from '@wishket/design-system';

const PrivacyUse = () => {
  return (
    <Box
      className="flex w-full flex-col gap-4"
      data-testid="marketing-policy-use-description"
    >
      <Box className="flex w-full flex-col items-start gap-2">
        <Typography variant="body3" className="font-normal text-w-gray-600">
          위시켓은 마케팅 정보제공을 목적으로 성명, 전화번호, 보유기술, 프로젝트
          이력 등 개인정보를 수집하고자 합니다.
        </Typography>
        <Typography variant="body3" className="font-normal text-w-gray-600">
          수집한 개인정보는 위시켓이 관리/운영하는 모든 서비스(위시켓, 요즘IT
          등)와 관련한 마케팅 정보제공에 이용될 예정입니다.
        </Typography>
      </Box>
      <Box className="flex w-full flex-col items-start gap-3">
        <Typography variant="body2" className="font-medium text-w-gray-900">
          개인정보 마케팅 활용 동의
        </Typography>
        <Box className="grid auto-rows-min grid-cols-[80px,min-content,1fr] divide-w-gray-200">
          <Divider className="col-span-3 bg-w-gray-900" />
          <Box className="bg-w-gray-10 px-4 py-3">
            <Typography variant="body3" className="font-normal text-w-gray-900">
              수집항목
            </Typography>
          </Box>
          <Divider isVertical />
          <Box className="px-4 py-3">
            <Typography
              variant="body3"
              className="inline-block font-normal text-w-gray-900"
            >
              성명, 생년월일, 성별, 전화번호, 이메일, 주소, 자기소개, 보유기술,
              프로젝트 선호 조건, 포트폴리오, 서비스 이용 기록, 방문 기록, 접속
              로그, 불량 이용 기록, 프로젝트 등록 이력, 프로젝트 진행 이력,
              프로젝트 지원 이력
            </Typography>
          </Box>
          <Divider className="col-span-3" />
          <Box className="bg-w-gray-10 px-4 py-3">
            <Typography variant="body3" className="font-normal text-w-gray-900">
              수집목적
            </Typography>
          </Box>
          <Divider isVertical />
          <Box className="px-4 py-3">
            <Typography
              variant="body3"
              className="inline-block font-normal text-w-gray-900"
            >
              서비스 안내, 프로젝트 추천, 파트너 추천, 뉴스레터 등 소식지 제공,
              이벤트 정보 및 참여 기회 제공, 아웃소싱 상담 서비스 제공(문자,
              이메일, TM, 앱푸쉬알림), 서비스 홍보를 위한 마케팅 활용, 신규
              서비스 요소 발굴 및 기존 서비스 개선, 통계에 따른 맞춤형 서비스
              제공 및 광고 게재
            </Typography>
          </Box>
          <Divider className="col-span-3" />
          <Box className="bg-w-gray-10 px-4 py-3">
            <Typography variant="body3" className="font-normal text-w-gray-900">
              보유기간
            </Typography>
          </Box>
          <Divider isVertical />
          <Box className="px-4 py-3">
            <Typography variant="body3" className="font-normal text-w-gray-900">
              회원탈퇴 또는 동의 철회 시
            </Typography>
          </Box>
          <Divider className="col-span-3" />
        </Box>
      </Box>
      <Box className="flex w-full flex-col items-start gap-2">
        <Typography variant="body3" className="font-normal text-w-gray-600">
          귀하께서는 선택 항목 수집∙이용에 대한 동의를 거부할 권리가 있습니다.
          단, 해당 항목 동의 거부 시 상기 이용목적에 명시된 서비스는 받으실 수
          없습니다.
        </Typography>
        <Typography variant="body3" className="font-normal text-w-gray-600">
          선택항목 동의 여부와 관계없이 필수 항목 동의에 의거한 기본 서비스는
          이용 가능합니다.
        </Typography>
      </Box>
    </Box>
  );
};

export default PrivacyUse;
