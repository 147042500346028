'use client';

import { twMerge } from 'tailwind-merge';
import React, { useState, useEffect } from 'react';
import { Box, Portal } from '@wishket/design-system';

import { useDeviceCheck } from '@/shared/hooks';
import { ToastItem, toast } from '@/shared/utils';

const ToastController: React.FC = () => {
  const { isDesktop } = useDeviceCheck();
  const [isScrolled, setIsScrolled] = useState(false);
  const [toasts, setToasts] = useState<ToastItem[]>([]);

  useEffect(() => {
    // toast 클래스의 subscribe 메서드를 통해 상태 변화 감지
    const unsubscribe = toast.subscribe(setToasts);

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (!toasts.length) return setIsScrolled(false);

    if (isDesktop && window.scrollY > 65) {
      setIsScrolled(true);
    }
    if (!isDesktop && window.scrollY > 61) {
      setIsScrolled(true);
    }
  }, [toasts, isDesktop]);

  return (
    <Portal>
      <Box
        className={twMerge(
          'fixed left-1/2 z-[9999] flex w-full -translate-x-1/2 flex-col gap-4 break-keep bg-transparent px-5 desktop:w-auto desktop:whitespace-pre',
          'top-[78px] desktop:top-[81px]',
          isScrolled && 'top-[16px] desktop:top-[16px]'
        )}
      >
        {toasts.map((toastItem) => (
          <Box key={toastItem.id}>{toastItem.component}</Box>
        ))}
      </Box>
    </Portal>
  );
};

export default ToastController;
