'use client';

import { Dispatch, SetStateAction, createContext } from 'react';

import { LoginMessageEnum } from '@/shared/types';

interface DialogContextValue {
  isNeedLinkOpen: boolean;
  isPartnerHasProjectOpen: boolean;
  loginMessage: LoginMessageEnum | null;
  close: () => void;
  setLoginMessage: Dispatch<SetStateAction<LoginMessageEnum | null>>;
  hasAgreement: boolean;
  submitResetProject: (callback?: () => void) => void;
}

const DialogContext = createContext<DialogContextValue>({
  isNeedLinkOpen: false,
  isPartnerHasProjectOpen: false,
  loginMessage: null,
  close: () => {},
  setLoginMessage: () => {},
  hasAgreement: false,
  submitResetProject: () => {},
});

export default DialogContext;
