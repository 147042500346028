'use client';

import { createContext } from 'react';

interface WithdrawalInfo {
  dueDate: string;
  isWithdrawalRequested?: boolean;
}

type WithdrawalContextType = {
  fetchWithdrawalInfo: () => void;
  withdrawalInfo: WithdrawalInfo;
  isLoading: boolean;
  isAlreadyRequestedWithdrawal: boolean;
};

const WithdrawalInfoContext = createContext<WithdrawalContextType | null>(null);

export default WithdrawalInfoContext;
