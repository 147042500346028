import { serverAxios } from '@/shared/api';
import { SocialLoginProvider } from '@/shared/types';
import { generateProviderBackendName } from '@/shared/utils';

interface SocialLinkApiParams {
  provider: SocialLoginProvider;
  socialAccessToken: string;
}

interface SocialUnlinkApiParams {
  provider: SocialLoginProvider;
  uuid: string;
  accessToken: string;
}

export const socialLinkInfoApi = async () => {
  return await serverAxios.get('/member/social/providers/');
};

export const socialLinkApi = async ({
  provider,
  socialAccessToken,
}: SocialLinkApiParams) => {
  const backendName = generateProviderBackendName(provider);

  return await serverAxios.post('/member/social/link/', {
    backend_name: backendName,
    access_token: socialAccessToken,
  });
};

export const socialUnlinkApi = async ({
  provider,
  accessToken,
  uuid,
}: SocialUnlinkApiParams) => {
  const backendName = generateProviderBackendName(provider);

  return await serverAxios.post('/member/social/unlink/', {
    backend_name: backendName,
    // 이름 확인하기
    access_token: accessToken,
    uid: uuid,
  });
};
