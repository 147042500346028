'use client';

import { ChangeEvent, useState } from 'react';

const useInput = <T>(initialValue: T) => {
  const [inputValue, setInputValue] = useState<T>(initialValue);
  const isObject = typeof inputValue === 'object';

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = e.target;

    const incomingValue =
      type === 'checkbox' || type === 'radio' ? checked : value;

    setInputValue(
      isObject
        ? ({ ...inputValue, [name]: incomingValue } as T)
        : (incomingValue as T)
    );
  };

  const handleTrimmedInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = e.target;

    const incomingValue =
      type === 'checkbox' || type === 'radio' ? checked : value.trim();

    setInputValue(
      isObject
        ? ({ ...inputValue, [name]: incomingValue } as T)
        : (incomingValue as T)
    );
  };

  const handleTextareaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setInputValue(
      isObject ? ({ ...inputValue, [name]: value } as T) : (value as T)
    );
  };

  return {
    inputValue,
    setInputValue,
    handleInputChange,
    handleTrimmedInputChange,
    handleTextareaChange,
  };
};

export default useInput;
