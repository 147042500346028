'use client';

import { createContext } from 'react';

export interface ProfileContextType {
  username: string;
  user_id: number;
  email: string;
  is_verification_required: boolean;
  first_signup_service: string;
}

const ProfileContext = createContext<ProfileContextType | undefined>(undefined);

export default ProfileContext;
