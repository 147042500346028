'use client';

import { Fragment, PropsWithChildren, useEffect, useState } from 'react';
import { usePathname } from 'next/navigation';
import { LoadingIndicator } from '@wishket/design-system';
import { twMerge } from 'tailwind-merge';

import { PATH } from '@/shared/constants';
import { ClientAuth } from '@/shared/auth';
import { useAuthCompletion } from '@/shared/hooks';

const EXCEPT_PATH: string[] = [
  PATH.TERMS_SERVICE,
  PATH.TERMS_PRIVACY,
  PATH.TERMS_YOUTH_PROTECTION,
  PATH.EMAIL_UNSUBSCRIBE,
];

const UnAuthGuardProvider = ({ children }: PropsWithChildren) => {
  const [isLogin, setIsLogin] = useState<boolean | undefined>(undefined);
  const pathName = usePathname();
  const { redirectNext } = useAuthCompletion();

  const isExceptPath =
    EXCEPT_PATH.includes(pathName) || pathName.startsWith(PATH.EMAIL_ACTIVATE);

  useEffect(() => {
    const validAuth = async () => {
      const accessToken = await ClientAuth.getAccessToken();
      setIsLogin(!!accessToken);
      return !!accessToken;
    };

    const handleAuth = async () => {
      const isValid = await validAuth();

      if (isValid) redirectNext();
    };

    if (!isExceptPath) handleAuth();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathName]);

  return (
    <Fragment>
      {!isExceptPath && isLogin === undefined ? (
        <LoadingIndicator size="lg" isFixed />
      ) : (
        <div className={twMerge(!isExceptPath && isLogin && 'hidden')}>
          {children}
        </div>
      )}
    </Fragment>
  );
};

export default UnAuthGuardProvider;
