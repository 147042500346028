'use client';

import { createContext } from 'react';

const DeviceCheckContext = createContext<{
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  isLoading: boolean;
}>({ isMobile: false, isTablet: false, isDesktop: false, isLoading: true });

export default DeviceCheckContext;
