'use client';

import { PropsWithChildren, useEffect } from 'react';
import { usePathname } from 'next/navigation';

import { useGetWithdrawalInfo } from '@/entities/withdrawal/info';
import { PATH } from '@/shared/constants';
import { WithdrawalInfoContext } from '@/entities/withdrawal';

const WithdrawalInfoProvider = ({ children }: PropsWithChildren) => {
  const {
    fetch: fetchWithdrawalInfo,
    data: withdrawalInfo,
    isLoading,
  } = useGetWithdrawalInfo();
  const pathname = usePathname();

  useEffect(() => {
    const isWithdrawalPage = pathname === PATH.WITHDRAWAL;
    if (!isWithdrawalPage) return;

    fetchWithdrawalInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const WithdrawalInfoContextValue = {
    fetchWithdrawalInfo,
    withdrawalInfo,
    isLoading,
    isAlreadyRequestedWithdrawal: !!withdrawalInfo?.dueDate,
  };

  return (
    <WithdrawalInfoContext.Provider value={WithdrawalInfoContextValue}>
      {children}
    </WithdrawalInfoContext.Provider>
  );
};

export default WithdrawalInfoProvider;
