'use client';

import { PropsWithChildren, useEffect, useState } from 'react';
import { LoadingIndicator } from '@wishket/design-system';

import { ClientAuth } from '@/shared/auth';
import { ProfileContext, ProfileContextType } from '@/entities/profile';
import { decodeAccessToken } from '@/shared/utils';

const ProfileProvider = ({ children }: PropsWithChildren) => {
  const [profile, setProfile] = useState<ProfileContextType | undefined>(
    undefined
  );

  useEffect(() => {
    const fetchData = async () => {
      const accessToken = await ClientAuth.getAccessToken();

      if (accessToken) {
        const decodedToken = decodeAccessToken(accessToken);

        if (decodedToken) setProfile(decodedToken);
      }
    };

    fetchData();
  }, []);

  if (!profile) return <LoadingIndicator size="lg" isFixed />;

  return (
    <ProfileContext.Provider value={profile}>
      {children}
    </ProfileContext.Provider>
  );
};

export default ProfileProvider;
