import { serverAxios } from '@/shared/api';

export interface mutateMarketingAgreementApiParams {
  isAgreeUse: boolean;
  isAgreeSubscribe: boolean;
}

export const marketingAgreementApi = async () => {
  return await serverAxios.get('/member/profile/my/');
};

export const mutateMarketingAgreementApi = async (
  data: mutateMarketingAgreementApiParams
) => {
  const sendData = {
    privacy_marketing_consent: data.isAgreeUse,
    receive_marketing_info_consent: data.isAgreeSubscribe,
  };

  return await serverAxios.patch('/member/marketing-consent/', sendData);
};
