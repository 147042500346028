import { ServiceType, SocialLoginProvider } from '@/shared/types';
import { generateProviderBackendName } from '@/shared/utils';
import { serverAxios } from '@/shared/api';

type LoginType = {
  id: string;
  password: string;
  remember: boolean;
  anonymousClientUuid?: string;
  isAppliedDelete?: boolean;
};

type RefreshType = {
  refreshToken: string;
};

type SocialLoginApiParams = {
  provider: SocialLoginProvider;
  accessToken: string;
  service: ServiceType;
  anonymousClientUuid?: string;
  isAppliedDelete?: boolean;
};

type LegacyRefreshApiParams = {
  wSessionId: string;
};

export const loginApi = async ({
  id,
  password,
  remember,
  anonymousClientUuid,
  isAppliedDelete,
}: LoginType) => {
  return await serverAxios.post('/member/login/', {
    auth_data: id,
    password,
    remember,
    anonymous_client_uuid: anonymousClientUuid,
    is_applied_delete: isAppliedDelete,
  });
};

export const refreshAccessTokenApi = async ({ refreshToken }: RefreshType) => {
  return await serverAxios.post('/member/refresh/', {
    refresh: refreshToken,
  });
};

export const socialLoginApi = async ({
  provider,
  accessToken,
  service,
  anonymousClientUuid,
  isAppliedDelete,
}: SocialLoginApiParams) => {
  const backendName = generateProviderBackendName(provider);

  return await serverAxios.post('/member/social/access/', {
    access_token: accessToken,
    backend_name: backendName,
    signup_service: service,
    anonymous_client_uuid: anonymousClientUuid,
    is_applied_delete: isAppliedDelete,
  });
};

export const legacyRefreshApi = async ({
  wSessionId,
}: LegacyRefreshApiParams) => {
  return await serverAxios.post(
    '/member/legacy-refresh/',
    {
      wsession_id: wSessionId,
    },
    {
      headers: {
        Cookie: `wsessionid=${wSessionId};`,
      },
    }
  );
};
