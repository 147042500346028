'use client';

import { Dispatch, SetStateAction, createContext, useContext } from 'react';

import { LoginMessageEnum, SocialLoginProvider } from '@/shared/types';

interface SocialAuthContextValue {
  isLoading: boolean;
  socialLoginMessageStatus: {
    get: LoginMessageEnum | null;
    set: Dispatch<SetStateAction<LoginMessageEnum | null>>;
  };
  providerStatus: {
    get: SocialLoginProvider | null;
    set: Dispatch<SetStateAction<SocialLoginProvider | null>>;
  };
  socialLoginPopupOpenStatus: {
    get: boolean;
    set: Dispatch<SetStateAction<boolean>>;
  };
  socialAccessTokenStatus: {
    get: string | null;
    set: Dispatch<SetStateAction<string | null>>;
  };
  fetchSocialLogin: (provider: SocialLoginProvider) => void;
  resetSocialAuthData: () => void;
}

export const SocialAuthContext = createContext<SocialAuthContextValue>({
  isLoading: false,
  socialLoginMessageStatus: {
    get: null,
    set: () => {},
  },
  providerStatus: {
    get: null,
    set: () => {},
  },
  socialLoginPopupOpenStatus: {
    get: false,
    set: () => {},
  },
  socialAccessTokenStatus: {
    get: null,
    set: () => {},
  },
  fetchSocialLogin: () => {},
  resetSocialAuthData: () => {},
});

const useSocialAuth = () => {
  const context = useContext(SocialAuthContext);

  return context;
};

export default useSocialAuth;
