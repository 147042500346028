'use client';

import { useState } from 'react';

import { clientAxios } from '@/shared/api';

const useWithdrawalRequest = () => {
  const [isRequested, setIsRequested] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fetchWithdrawalRequest = async () => {
    setIsLoading(true);

    try {
      const { data } = await clientAxios.post('/withdrawalRequestApi');

      if (data.ok) {
        setIsRequested(true);
      } else {
        throw new Error();
      }
    } catch (e) {
      /* empty */
    } finally {
      setIsLoading(false);
    }
  };

  return { isRequested, isLoading, fetch: fetchWithdrawalRequest };
};

export default useWithdrawalRequest;
