'use client';

import Link from 'next/link';
import { useSelectedLayoutSegments } from 'next/navigation';
import { Box, SystemIcon, Typography } from '@wishket/design-system';

import PATH from '@/shared/constants/path';
import { useNextUrl } from '@/shared/hooks';

const PAGE_NAME: Record<string, string> = {
  account: '계정 정보',
  password: '비밀번호 변경',
  withdrawal: '회원 탈퇴',
};

const Breadcrumb = () => {
  const segments = useSelectedLayoutSegments('content');
  const { withNextUrl } = useNextUrl();

  const pageName = segments ? PAGE_NAME[segments[1]] : '';

  return (
    <Box className="flex items-center gap-[0.45rem] font-medium text-w-gray-600">
      <Link href={withNextUrl(PATH.HOME)} className="group box-border">
        <Typography
          variant="body1"
          className="underline-offset-2 group-hover:underline"
        >
          계정 설정
        </Typography>
      </Link>
      <SystemIcon name="medium_arrow_right" className="text-gray-600" />
      <Typography variant="body1">{pageName}</Typography>
    </Box>
  );
};

export default Breadcrumb;
