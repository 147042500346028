'use client';

import { useSearchParams } from 'next/navigation';

const useNextUrl = () => {
  const searchParams = useSearchParams();
  const nextUrl = searchParams.get('next') || '';

  const encodedNextUrl = encodeURIComponent(nextUrl);
  const decodedNextUrl = decodeURIComponent(nextUrl);

  const withNextUrl = (url: string) => {
    return nextUrl ? `${url}?next=${encodedNextUrl}` : url;
  };

  const redirectNextUrl = (url: string) => {
    const decodedUrl = decodeURIComponent(url);

    return nextUrl ? `${decodedUrl}${decodedNextUrl}` : decodedUrl;
  };

  return { nextUrl: decodedNextUrl, withNextUrl, redirectNextUrl };
};

export default useNextUrl;
