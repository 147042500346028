import { Box, Typography } from '@wishket/design-system';

const PrivacySubscribe = () => {
  return (
    <Box
      className="flex w-full flex-col gap-4"
      data-testid="marketing-policy-subscribe-description"
    >
      <Box className="flex w-full flex-col items-start gap-2">
        <Typography variant="body2" className="font-medium text-w-gray-900">
          마케팅 정보 수신 동의
        </Typography>
        <Typography variant="body3" className="font-normal text-w-gray-600">
          위시켓은 귀하께서 수집 및 이용에 동의한 개인정보를 활용하여 이메일,
          전화, 문자메시지, 푸쉬알림 등 다양한 전자적 전송매체를 통해 오전
          8시부터 오후 9시까지(이메일은 제외) 위시켓이 관리/운영하는 모든
          서비스(위시켓, 요즘IT 등)와 관련한 개인 맞춤형 광고 및 정보를 전송할
          수 있습니다.
        </Typography>
        <Typography variant="body3" className="font-normal text-w-gray-600">
          귀하께서는 선택 항목 수집∙이용에 대한 동의를 거부할 권리가 있습니다.
          단, 해당 항목 동의 거부 시 상기 이용목적에 명시된 서비스는 받으실 수
          없습니다.
        </Typography>
        <Typography variant="body3" className="font-normal text-w-gray-600">
          선택항목 동의 여부와 관계없이 필수 항목 동의에 의거한 기본 서비스는
          이용 가능합니다.
        </Typography>
      </Box>
      <Box className="flex w-full flex-col items-start gap-2">
        <Typography variant="body3" className="font-normal text-w-gray-600">
          광고성 정보 수신의 변경은
        </Typography>
        <Box className="flex flex-col items-start gap-1">
          <Box className="flex items-start gap-1">
            <Typography variant="body3" className="font-normal text-w-gray-600">
              ①
            </Typography>
            <Typography variant="body3" className="font-normal text-w-gray-600">
              위시켓 고객센터(02-6925-4849, help@wishket.com)로 요청주시거나
            </Typography>
          </Box>
          <Box className="flex items-start gap-1">
            <Typography variant="body3" className="font-normal text-w-gray-600">
              ②
            </Typography>
            <Typography variant="body3" className="font-normal text-w-gray-600">
              {
                '[계정 설정] > [계정 정보·소셜 연동]에서 언제든지 변경 가능합니다.'
              }
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PrivacySubscribe;
