'use client';

import { useContext } from 'react';

import { DialogContext } from '@/shared/providers';

const useDialog = () => {
  const context = useContext(DialogContext);

  return context;
};

export default useDialog;
