'use client';

import { useContext, useEffect } from 'react';
import { usePathname, useRouter } from 'next/navigation';
import { datadogRum } from '@datadog/browser-rum';

import ProfileContext, { ProfileContextType } from './ProfileContext';

import { PATH } from '@/shared/constants';
import { useNextUrl } from '@/shared/hooks';

const useProfile = (): ProfileContextType => {
  const router = useRouter();
  const pathname = usePathname();
  const profile = useContext(ProfileContext) as ProfileContextType;
  const { withNextUrl } = useNextUrl();

  useEffect(() => {
    if (!profile) return;

    const {
      username,
      user_id,
      email,
      is_verification_required,
      first_signup_service,
    } = profile;

    datadogRum.setUser({
      id: String(user_id),
      name: username,
      email,
      first_signup_service,
    });

    //비정상적인 계정 정보
    if (!username || !user_id || !email)
      router.replace(withNextUrl(PATH.LOGIN));

    //이메일 인증 필요 시
    // TODO: 해당 위젯 노출 대상 재정의 필요.
    if (is_verification_required && pathname !== PATH.SIGNUP_COMPLETE)
      router.replace(withNextUrl(PATH.SIGNUP_EMAIL_VERIFY));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, profile]);

  return {
    username: profile?.username ?? '',
    user_id: profile?.user_id ?? 0,
    email: profile?.email ?? '',
    is_verification_required: profile?.is_verification_required ?? false,
    first_signup_service: profile?.first_signup_service ?? '',
  };
};

export default useProfile;
