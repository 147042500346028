'use client';

import { Box } from '@wishket/design-system';
import { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

import Portal from '../Portal';

import { usePreventScroll } from '@/shared/hooks';

interface DialogProps extends PropsWithChildren {
  testId?: string;
  className?: string;
}

const Dialog = ({ testId, children, className }: DialogProps) => {
  usePreventScroll();
  return (
    <Portal elementId="dialog-root">
      <Box
        className="fixed left-0 top-0 z-[60] h-screen w-screen bg-w-gray-500 opacity-50"
        data-testid={`${testId}--outside`}
      />
      <Box
        data-testid={testId}
        className={twMerge(
          'fixed left-1/2 top-1/2 z-[999] flex -translate-x-1/2 -translate-y-1/2 items-center justify-center rounded-xl bg-w-white',
          className ??
            'min-h-40 min-w-[305px] tablet:min-w-[480px] desktop:min-w-[480px]'
        )}
      >
        <Box className="flex h-full w-full flex-col items-center justify-center gap-6">
          {children}
        </Box>
      </Box>
    </Portal>
  );
};

export default Dialog;
