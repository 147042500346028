'use client';

import {
  Box,
  Divider,
  Modal,
  SystemIcon,
  Typography,
} from '@wishket/design-system';
import { twMerge } from 'tailwind-merge';

import PrivacyUse from './PrivacyUse';
import PrivacySubscribe from './PrivacySubscribe';

import { MarketingAgreementType } from '@/shared/types';
import { useDeviceCheck } from '@/shared/hooks';
import { getScrollbarStyle } from '@/shared/utils';

interface PolicyModalProps {
  isOpen: boolean;
  onClose: () => void;
  type: MarketingAgreementType;
}

const PolicyModal = ({ isOpen, onClose, type }: PolicyModalProps) => {
  const { isMobile, isLoading } = useDeviceCheck();

  if (isLoading) return null;

  const isUsePolicy = type === MarketingAgreementType.USE;
  const isSubscribePolicy = type === MarketingAgreementType.SUBSCRIBE;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={isMobile ? 'full' : 'sm'}
      isFixedHeight={true}
    >
      <Box
        className="flex h-full max-h-[80vh] w-full flex-col items-center"
        data-testid="marketing-policy-modal"
      >
        <Box className="flex h-full w-full items-center justify-between gap-6 px-8 py-6">
          <Typography
            variant="subTitle2"
            className="font-medium text-w-gray-900"
          >
            {isUsePolicy && '개인정보 마케팅 활용 동의'}
            {isSubscribePolicy && '마케팅 정보 수신 동의'}
          </Typography>
          <Box
            onClick={onClose}
            className="cursor-pointer"
            data-testid="marketing-policy-modal--close-button"
          >
            <SystemIcon name="large_delete" className="text-w-gray-600" />
          </Box>
        </Box>
        <Divider />
        <Box
          className={twMerge(
            'flex-1 overflow-y-scroll py-6',
            getScrollbarStyle({ modalType: 'notFull' })
          )}
        >
          {isUsePolicy && <PrivacyUse />}
          {isSubscribePolicy && <PrivacySubscribe />}
        </Box>
      </Box>
    </Modal>
  );
};

export default PolicyModal;
