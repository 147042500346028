'use client';

import { useEffect, useRef } from 'react';

const DEFAULT_DELAY_TIME = 500;

const useThrottle = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback: (...args: any) => void,
  delay: number = DEFAULT_DELAY_TIME
) => {
  // eslint-disable-next-line no-undef
  const timerId = useRef<NodeJS.Timeout | undefined>(undefined);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const throttledCallback = (...args: any) => {
    if (timerId.current) return;
    callback(...args);
    timerId.current = setTimeout(() => {
      timerId.current = undefined;
    }, delay);
  };

  useEffect(() => {
    return () => {
      if (timerId) {
        clearTimeout(timerId.current);
      }
    };
  }, [timerId]);

  return throttledCallback;
};

export default useThrottle;
