'use client';

import { PropsWithChildren, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

interface PortalProps extends PropsWithChildren {
  elementId: string;
}

export const Portal = ({ children, elementId }: PortalProps) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => setMounted(true), []);

  return mounted
    ? createPortal(
        <>{children}</>,
        document.getElementById(elementId) as HTMLElement
      )
    : null;
};
