'use client';

import { useContext } from 'react';

import { DeviceCheckContext } from '@/shared/providers';

const useDeviceCheck = () => {
  return useContext(DeviceCheckContext);
};

export default useDeviceCheck;
