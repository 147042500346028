'use client';

import { useEffect, useState } from 'react';
import { getSession, signOut } from 'next-auth/react';
import { isAxiosError } from 'axios';

import { clientAxios } from '@/shared/api';
import { SocialLoginProvider, SocialSession } from '@/shared/types';
import { useSocialLogin } from '@/entities/login';
import {
  SocialLinkStorage,
  generateSocialProviderName,
  notifyToast,
} from '@/shared/utils';
import { useSocialAuth } from '@/shared/providers';

interface ErrorData {
  msg?: string;
  provider?: SocialLoginProvider;
  username?: string;
  email?: string;
}

interface ErrorStatus {
  isError: boolean;
  status?: number;
  data: ErrorData | null;
}

const INIT_ERROR_STATUS = {
  isError: false,
  status: 0,
  data: null,
  provider: 'google',
};

const useSocialLink = () => {
  const [socialLinkInfo, setSocialLinkInfo] = useState({
    isActiveGoogle: false,
    isActiveFacebook: false,
  });
  const [isInitLoading, setIsInitLoading] = useState(true);
  const [isFetchLoading, setIsFetchLoading] = useState(false);
  const [error, setError] = useState<ErrorStatus>(INIT_ERROR_STATUS);

  const { reset } = useSocialLogin();
  const {
    fetchSocialLogin,
    socialLoginPopupOpenStatus,
    socialAccessTokenStatus,
    resetSocialAuthData,
  } = useSocialAuth();

  const clickSocialLink = async (provider: SocialLoginProvider) => {
    resetError();
    setIsFetchLoading(true);

    SocialLinkStorage.set({ provider, type: 'link' });
    const session = (await getSession()) as SocialSession;

    try {
      if (
        !session ||
        !session.account ||
        session.account.provider !== provider
      ) {
        await fetchSocialLogin(provider);

        return;
      }

      const accessToken = session.account.access_token;

      const { data } = await clientAxios.post('/socialLinkApi', {
        provider,
        socialAccessToken: accessToken,
      });

      if (data.ok) {
        reset();
        SocialLinkStorage.remove();

        await fetchSocialLinkInfo();
      } else {
        throw new Error(data.error);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      if (isAxiosError(e) && e.response) {
        const {
          error: { status, data },
        } = e.response.data;

        if (status === 400) {
          setError({
            isError: true,
            status: status,
            data: {
              msg: data.msg,
              provider: provider,
              username: data.username,
              email: data.email,
            },
          });
        }
        const providerName = generateSocialProviderName(provider);

        if (status === 401 && provider === 'facebook') {
          setError({ ...error, isError: true });
          notifyToast({
            type: 'error',
            message: `${providerName} 이메일 엑세스 권한을 허용해주세요.`,
          });
        }

        if (status === 500) {
          setError({ ...error, isError: true });
          notifyToast({
            type: 'error',
            message: `${providerName} 연동중 오류가 발생했습니다. 다시 시도해주세요.`,
          });
        }
      }
    } finally {
      setIsFetchLoading(false);
      signOut({ redirect: false });
    }
  };

  const clickSocialUnlink = async (provider: SocialLoginProvider) => {
    resetError();

    SocialLinkStorage.set({ provider, type: 'unlink' });
    const session = (await getSession()) as SocialSession;

    try {
      setIsFetchLoading(true);

      if (
        !session ||
        !session.account ||
        session.account.provider !== provider
      ) {
        await fetchSocialLogin(provider);

        return;
      }

      const { data } = await clientAxios.post('/socialUnlinkApi', {
        provider,
        uuid: session.account.providerAccountId,
        accessToken: session.account.access_token,
      });

      if (data.ok) {
        SocialLinkStorage.remove();

        await fetchSocialLinkInfo();
        reset();
      } else {
        throw new Error(data.error);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      const providerName = generateSocialProviderName(provider);

      if (isAxiosError(e) && e.response) {
        const {
          error: { status },
        } = e.response.data;
        setError({ ...error, isError: true });

        if (status === 401 && provider === 'facebook') {
          notifyToast({
            type: 'error',
            message: `${providerName} 이메일 엑세스 권한을 허용해주세요.`,
          });
        } else if (status === 401 && provider === 'google') {
          notifyToast({
            type: 'error',
            message: `${providerName}로 연동한 계정과 다른 계정입니다. 올바른 계정으로 다시 시도해주세요.`,
          });
        } else {
          notifyToast({
            type: 'error',
            message: `${providerName} 연동을 해제하는 중 오류가 발생했습니다. 다시 시도해주세요.`,
          });
        }
      }
    } finally {
      setIsFetchLoading(false);
      signOut({ redirect: false });
    }
  };

  const fetchSocialLinkInfo = async () => {
    try {
      const { data } = await clientAxios.get('/socialLinkInfoApi');

      if (data.ok) {
        const isActiveGoogle =
          data.data.linked_providers.includes('google-oauth2');
        const isActiveFacebook =
          data.data.linked_providers.includes('facebook');

        setSocialLinkInfo({
          isActiveGoogle,
          isActiveFacebook,
        });
      } else {
        throw new Error(data.error);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      if (isAxiosError(e) && e.response) {
        const {
          error: { status, data },
        } = e.response.data;
        setError({
          isError: true,
          status: status || 0,
          data: {
            msg: data?.msg,
            username: data?.username,
            email: data?.email,
          },
        });
      }
    } finally {
      setIsInitLoading(false);
    }
  };

  const resetError = () => {
    setError(INIT_ERROR_STATUS);
  };

  useEffect(() => {
    const fetchPendingSocialLink = async () => {
      const socialLinkProvider = SocialLinkStorage.get();

      if (
        !socialLoginPopupOpenStatus.get &&
        !!socialLinkProvider &&
        !!socialAccessTokenStatus.get
      ) {
        if (socialLinkProvider.type === 'link')
          await clickSocialLink(
            socialLinkProvider.provider as SocialLoginProvider
          );
        if (socialLinkProvider.type === 'unlink')
          await clickSocialUnlink(
            socialLinkProvider.provider as SocialLoginProvider
          );
      }
    };

    fetchPendingSocialLink();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socialLoginPopupOpenStatus.get, socialAccessTokenStatus.get]);

  useEffect(() => {
    fetchSocialLinkInfo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const socialInfoReset = async () => {
      SocialLinkStorage.remove();
      resetSocialAuthData();

      await signOut({ redirect: false });
    };

    if (error.isError) socialInfoReset();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error.isError]);

  return {
    error,
    resetError,
    socialLinkInfo,
    isInitLoading,
    isFetchLoading: socialLoginPopupOpenStatus.get || isFetchLoading,
    clickSocialLink,
    clickSocialUnlink,
  };
};

export default useSocialLink;
