import { useContext } from 'react';

import WithdrawalInfoContext from './WithdrawalInfoContext';

const useWithdrawalInfo = () => {
  const value = useContext(WithdrawalInfoContext);
  if (!value) {
    throw new Error(
      'useWithdrawalInfo must be used within a WithdrawalInfoProvider'
    );
  }
  return value;
};

export default useWithdrawalInfo;
