'use client';

import { useEffect, useState } from 'react';
import { isAxiosError } from 'axios';

import { ApiErrorType, LoginData, LoginMessageEnum } from '@/shared/types';
import {
  fetchLoginApi,
  getAnonymousClientUuid,
  validateLoginResponse,
} from '@/shared/utils';
import { deleteAnonymousClientUuid } from '@/shared/utils/auth';
import { ClientAuth } from '@/shared/auth';
import { useAuthCompletion } from '@/shared/hooks';

interface fetchLoginParams {
  id: string;
  password: string;
  remember: boolean;
  isAppliedDelete?: boolean;
}

const A_SECOND = 1000;
const FIVE_SECONDS = 5 * A_SECOND;

const useLogin = () => {
  const [isLogin, setIsLogin] = useState<boolean | undefined>(undefined);
  const [data, setData] = useState<LoginData | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<ApiErrorType['error'] | null>(null);

  const { redirectNext } = useAuthCompletion();

  const reset = () => {
    setIsLogin(undefined);
    setData(null);
    setError(null);
  };

  const fetch = async ({
    isAppliedDelete = false,
    id,
    password,
    remember,
  }: fetchLoginParams) => {
    setIsLoading(true);
    try {
      const anonymousClientUuid = getAnonymousClientUuid();

      if (isAppliedDelete) deleteAnonymousClientUuid();

      const response = await fetchLoginApi(
        id,
        password,
        remember,
        anonymousClientUuid,
        isAppliedDelete
      );
      const { data } = response;

      if (data.ok) {
        setData(data.data);
        const { access, refresh, msg } = data.data;

        if (validateLoginResponse(msg) && !!access && !!refresh) {
          await ClientAuth.authenticate(access, refresh);
          await deleteAnonymousClientUuid();
          setIsLogin(true);
        } else if (msg === LoginMessageEnum.PARTNER_HAS_PROJECT) {
          setIsLogin(undefined);
        }
      } else {
        throw new Error();
      }
    } catch (e) {
      if (isAxiosError(e) && e.response) {
        const { error } = e.response.data;

        setError(error);
        setIsLogin(false);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const validAuth = async () => {
      const accessToken = await ClientAuth.getAccessToken();
      setIsLogin(!!accessToken);

      return !!accessToken;
    };

    const checkLoginStatus = () => {
      setIsLoading(true);
      validAuth().finally(() => setIsLoading(false));
    };

    const checkLoginStatusOnBackground = () => {
      validAuth().then((res) => {
        if (res) redirectNext();
      });
    };

    const intervalTime = FIVE_SECONDS;

    checkLoginStatus();

    const intervalId = setInterval(checkLoginStatusOnBackground, intervalTime);

    window.addEventListener('focus', checkLoginStatusOnBackground);

    return () => {
      window.removeEventListener('focus', checkLoginStatusOnBackground);
      clearInterval(intervalId);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    fetch,
    reset,
    isLogin,
    data,
    isLoading,
    error,
  };
};

export default useLogin;
