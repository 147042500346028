'use client';

import { useState } from 'react';

import { MarketingAgreementType } from '@/shared/types';

const usePolicyModal = () => {
  const [isPolicyModalOpen, setIsPolicyModalOpen] = useState<boolean>(false);
  const [policyModalType, setPolicyModalType] =
    useState<MarketingAgreementType>(MarketingAgreementType.USE);

  const openPolicyModal = (type: MarketingAgreementType) => {
    setPolicyModalType(type);
    setIsPolicyModalOpen(true);
  };

  const closePolicyModal = () => {
    setIsPolicyModalOpen(false);
  };

  return {
    isPolicyModalOpen,
    policyModalType,
    openPolicyModal,
    closePolicyModal,
  };
};

export default usePolicyModal;
