'use client';

import { PropsWithChildren, useEffect, useState } from 'react';
import { signOut } from 'next-auth/react';

import DialogContext from './DialogContext';

import { LoginMessageEnum } from '@/shared/types';

const DialogProvider = ({ children }: PropsWithChildren) => {
  const [isNeedLinkOpen, setIsNeedLinkOpen] = useState(false);
  const [isPartnerHasProjectOpen, setIsPartnerHasProjectOpen] = useState(false);
  const [loginMessage, setLoginMessage] = useState<LoginMessageEnum | null>(
    null
  );
  const [hasAgreement, setHasAgreement] = useState(false);

  const closeDialog = () => {
    setIsNeedLinkOpen(false);
    setIsPartnerHasProjectOpen(false);
    setLoginMessage(null);
    signOut({ redirect: false });
  };

  const submitResetProject = (callback?: () => void) => {
    setHasAgreement(true);
    setIsPartnerHasProjectOpen(false);
    callback && callback();
  };

  useEffect(() => {
    if (loginMessage === LoginMessageEnum.NEED_LINK) {
      setIsNeedLinkOpen(true);
      signOut({ redirect: false });
    } else if (loginMessage === LoginMessageEnum.PARTNER_HAS_PROJECT) {
      setIsPartnerHasProjectOpen(true);
    }
  }, [loginMessage]);

  const dialogContextValue = {
    isNeedLinkOpen,
    isPartnerHasProjectOpen,
    loginMessage,
    close: closeDialog,
    setLoginMessage,
    hasAgreement,
    submitResetProject,
  };

  return (
    <DialogContext.Provider value={dialogContextValue}>
      {children}
    </DialogContext.Provider>
  );
};

export default DialogProvider;
