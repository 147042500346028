'use client';

import { useEffect, useState } from 'react';

import { mutateMarketingAgreementApiParams } from '../apis';

import { clientAxios } from '@/shared/api';
import { notifyToast } from '@/shared/utils';
import { MarketingAgreementType } from '@/shared/types';

interface ToggleStatus {
  isAgreeUse: boolean;
  isAgreeSubscribe: boolean;
}

interface MarketingAgreement {
  toggleStatus: ToggleStatus;
}

interface MutateParams {
  isAgreeWithdrawal?: boolean;
}

const useMarketingAgreement = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [agreement, setAgreement] = useState<MarketingAgreement>({
    toggleStatus: {
      isAgreeUse: false,
      isAgreeSubscribe: false,
    },
  });
  const [isMarketingWithdrawalModalOpen, setIsMarketingWithdrawalModalOpen] =
    useState(false);

  const [marketingWithdrawalType, setMarketingWithdrawalType] =
    useState<MarketingAgreementType | null>(null);

  const fetcher = async (data: mutateMarketingAgreementApiParams) => {
    try {
      setAgreement((prev) => ({
        ...prev,
        toggleStatus: data,
      }));

      const { data: responseData } = await clientAxios.patch(
        '/mutateMarketingAgreementApi',
        data
      );

      return responseData;
    } catch (error) {
      console.error(
        '계정 설정 화면 마케팅 동의 변경 실패',
        JSON.stringify({
          extra: {
            error: JSON.stringify(error),
          },
        })
      );

      await getMarketingAgreement();

      return null;
    }
  };

  const mutateUse = async ({ isAgreeWithdrawal = false }: MutateParams) => {
    const isAgreeUse = agreement.toggleStatus.isAgreeUse;

    if (isAgreeUse) {
      await handleDisagree(isAgreeWithdrawal, MarketingAgreementType.USE);
    } else {
      await fetchAgreements(MarketingAgreementType.USE);
    }
  };

  const mutateSubscribe = async ({
    isAgreeWithdrawal = false,
  }: MutateParams) => {
    const isAgreeSubscribe = agreement.toggleStatus.isAgreeSubscribe;

    if (isAgreeSubscribe) {
      await handleDisagree(isAgreeWithdrawal, MarketingAgreementType.SUBSCRIBE);
    } else {
      await fetchAgreements(MarketingAgreementType.SUBSCRIBE);
    }
  };

  const handleDisagree = async (
    isAgreeWithdrawal: boolean,
    type: MarketingAgreementType
  ) => {
    if (isAgreeWithdrawal) {
      await revokeAgreements(type);
    } else {
      openMarketingWithdrawalModal(type);
    }
  };

  const fetchAgreements = async (type: MarketingAgreementType) => {
    try {
      const response = await fetcher({
        isAgreeUse: true,
        isAgreeSubscribe: true,
      });

      if (response.ok) {
        const dateMessage =
          type === MarketingAgreementType.USE
            ? response.data.date_privacy_marketing_consent
            : response.data.date_receive_marketing_info_consent;

        notifyToast({
          type: 'success',
          message: `마케팅 정보 수신 동의가 정상 처리되었습니다. (${dateMessage.replace(/-/g, '.')}.)`,
        });
      } else {
        throw new Error('Failed to fetchAgreements');
      }
    } catch {
      notifyToast({
        type: 'error',
        message: `마케팅 정보 수신 동의가 실패하였습니다.`,
      });
    }
  };

  const revokeAgreements = async (type: MarketingAgreementType) => {
    const updates = {
      isAgreeUse:
        type === MarketingAgreementType.USE
          ? false
          : agreement.toggleStatus.isAgreeUse,
      isAgreeSubscribe: false,
    };

    const isUsageRevokeOnly =
      type === MarketingAgreementType.USE &&
      !agreement.toggleStatus.isAgreeSubscribe;

    try {
      const response = await fetcher(updates);

      if (response.ok) {
        const dateMessage =
          type === MarketingAgreementType.USE
            ? response.data.date_reject_privacy_marketing_consent
            : response.data.date_reject_receive_marketing_info_consent;

        if (isUsageRevokeOnly) return;

        notifyToast({
          type: 'success',
          message: `마케팅 정보 수신 동의 철회가 정상 처리되었습니다. (${dateMessage.replace(/-/g, '.')}.)`,
        });
      } else {
        throw new Error('Failed to fetchAgreements');
      }
    } catch (e) {
      notifyToast({
        type: 'error',
        message: `마케팅 정보 수신 동의 철회가 실패하였습니다.`,
      });
    }
  };

  const openMarketingWithdrawalModal = (type: MarketingAgreementType) => {
    setMarketingWithdrawalType(type);
    setIsMarketingWithdrawalModalOpen(true);
  };

  const closeMarketingWithdrawalModal = () => {
    setIsMarketingWithdrawalModalOpen(false);
  };

  const agreeWithdrawal = () => {
    setIsMarketingWithdrawalModalOpen(false);

    if (marketingWithdrawalType === MarketingAgreementType.USE) {
      mutateUse({ isAgreeWithdrawal: true });
    }

    if (marketingWithdrawalType === MarketingAgreementType.SUBSCRIBE) {
      mutateSubscribe({ isAgreeWithdrawal: true });
    }
  };

  const getMarketingAgreement = async () => {
    try {
      const { data } = await clientAxios.get('/marketingAgreementApi');

      const responseData = data.data;

      setAgreement({
        toggleStatus: {
          isAgreeUse: responseData.privacy_marketing_consent,
          isAgreeSubscribe: responseData.receive_marketing_info_consent,
        },
      });
    } catch (error) {
      console.error(
        '계정 설정 화면 마케팅 동의 조회 실패',
        JSON.stringify({
          extra: {
            error: JSON.stringify(error),
          },
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getMarketingAgreement();
  }, []);

  return {
    data: agreement,
    mutate: {
      use: mutateUse,
      subscribe: mutateSubscribe,
    },
    isLoading,
    marketingWithdrawalModal: {
      isOpen: isMarketingWithdrawalModalOpen,
      onClose: closeMarketingWithdrawalModal,
    },
    onAgreeWithdrawal: agreeWithdrawal,
    marketingWithdrawalType,
  };
};

export default useMarketingAgreement;
